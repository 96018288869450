<template>
  <div>
    <!--图片-->
<!--    <div class="pic" style="height: 500px">-->
<!--      <img style="width: 100%" src="../../assets/imagebox/2.png">-->
<!--      <h3 style="position: absolute; top: 130px; left: 35%; font-size: 45px; color: white">{{ businessInfo1 }}</h3>-->
<!--      <p style="position: absolute; top: 250px; left: 38%; font-size: 20px; color: white; width: 550px">{{ businessInfo2 }}</p>-->
<!--      <el-button type="primary" style="position: absolute; color: white; left: 45%; top: 350px; font-size: 20px;">立即咨询</el-button>-->
<!--    </div>-->
    <div class="pic">
      <div style="margin-top: 100px">
        <h3 style="font-size: 45px; color: white">{{ businessInfo1 }}</h3>
      </div>
      <div>
        <span style="width: 80%; font-size: 20px; color: white;">{{ businessInfo2 }}</span>
      </div>
      <div style="margin-top: 50px">
        <el-button type="primary" style="font-size: 20px;">立即咨询</el-button>
      </div>
    </div>

    <div class="part2">
      <div class="partheader">
        <h>{{ part2Info1 }}</h>
      </div>
      <div class="part2info">
        <p>{{ part2Info2 }}</p>
      </div>
      <div class="part2info">
        <p>{{ part2Info3 }}</p>
      </div>
    </div>

    <div class="part3">
      <div class="partheader">
        <h>八大支持</h>
      </div>
      <div class="part3row">
        <div class="part3info">
          <img class="part3img" src="../../assets/business/sys1.png">
          <p>授权使用大数据运营管理平台</p>
        </div>
        <div class="part3info">
          <img class="part3img" src="../../assets/business/sys2.png">
          <p>提供智慧终端产品及解决方案</p>
        </div>
      </div>
      <div class="part3row">
        <div class="part3info">
          <img class="part3img" src="../../assets/business/sys3.png">
          <p>提供平台运营技术支撑和使用指导</p>
        </div>
        <div class="part3info">
          <img class="part3img" src="../../assets/business/sys4.png">
          <p>提供后台接口，提供平台搭建、定制产品设计开发、APP开发服务</p>
        </div>
      </div>
      <div class="part3row">
        <div class="part3info">
          <img class="part3img" src="../../assets/business/sys5.png">
          <p>提供自身渠道推广资源和政府行业客户资源</p>
        </div>
        <div class="part3info">
          <img class="part3img" src="../../assets/business/sys6.png">
          <p>提供正常运营所需要的服务器、数据库及所需的带宽资源等设计方案</p>
        </div>
      </div>
      <div class="part3row">
        <div class="part3info">
          <img class="part3img" src="../../assets/business/sys7.png">
          <p>提供专业技术培训与业务及运营服务指导</p>
        </div>
        <div class="part3info">
          <img class="part3img" src="../../assets/business/sys8.png">
          <p>提供统一的市场宣传设计指引</p>
        </div>
      </div>
    </div>

    <div class="part4">
      <div class="partheader">
        <h>合作条件</h>
      </div>
      <div class="part4_row">
        <el-card class="part4_card">
          <img class="part4_card_img" style="margin-left: 40%" src="../../assets/business/file.png">
          <h3 style="margin-left: 20%; color: #696969; font-size: 20px">合作伙伴的基本条件</h3>
          <p style="font-size: 14px;">一、具备地方行业渠道资源</p>
          <p style="font-size: 14px;">二、具有或能够组建高效的、强有力的销售团队</p>
          <p style="font-size: 14px;">三、具有以下条件者优先：</p>
          <p style="font-size: 12px; text-indent:2em;">具备施工、检测、设计或行业系统集成等相关资质；</p>
          <p style="font-size: 12px; text-indent:2em;">具备行业或政府客户资源，具有本地教育、卫生、文化、公安等行业过往案例介绍；</p>
          <p style="font-size: 12px; text-indent:2em">对物联网行业有一定了解，对消防物联网的事业具备热情和干劲。</p>
        </el-card>
        <el-card class="part4_card">
          <img class="part4_card_img" style="margin-left: 40%" src="../../assets/business/files.png">
          <h3 style="margin-left: 19%; color: #696969; font-size: 20px">合作伙伴需要提供的资料</h3>
          <p style="font-size: 14px;">一、营业执照复印件（盖章）</p>
          <p style="font-size: 14px;">二、法人身份证（盖章）</p>
          <p style="font-size: 14px;">三、企业基本情况介绍</p>
        </el-card>
        <el-card class="part4_card">
          <img class="part4_card_img" style="margin-left: 40%" src="../../assets/business/hezuo.png">
          <h3 style="margin-left: 30%; color: #696969; font-size: 20px">商务合作方式</h3>
          <p style="font-size: 14px;">一、三个方面授权：区域授权+平台授权+大客户授权</p>
          <p style="font-size: 14px;">二、地区授权：城市+区县</p>
          <p style="font-size: 14px;">三、合作周期：两年一签（突破重大项目，复制经验多可优先签约）</p>
        </el-card>
      </div>
    </div>
    <BottomDiv></BottomDiv>
  </div>
</template>

<script>
import BottomDiv from "@/components/BottomDiv.vue";

export default {
  name: 'HelloWorld',
  components: { BottomDiv },
  data() {
    return {
      activeMenu: "1",
      imagebox: [

        {id:0,idView:require('../../assets/imagebox/1.png')},
        {id:1,idView:require('../../assets/imagebox/2.png')},
        {id:2,idView:require('../../assets/imagebox/3.png')},
        {id:3,idView:require('../../assets/imagebox/4.png')}
        //imagebox是assets下一个放图片的文件夹
      ],

      businessInfo1: "合作伙伴招募",
      businessInfo2: "致力于与合作伙伴共建、共赢，各自发挥优势能力，完成一体化解决方案交付",

      part2Info1: "产业中心",
      part2Info2: "    随着智慧云平台全国业务的发展，在快速发展的市场中，需要借助更多生态伙伴的力量产生聚合效应，" +
          "共同开启智慧解决方案的万亿蓝海市场。智慧云平台是一款自主研发，适用于各种场所的物联网远程监控系统，" +
          "旨在全方位解决城市、企业安全问题，现在诚邀有资质有能力有热情的伙伴加入，共建通路，共享利益。",
      part2Info3: "    秉承技术价值和互联互通的开放心态，通过大数据运营服务平台为主体的平台优势，以不断扩展的终端产品开发为基点，" +
          "结合产品定制开发能力和运营支撑能力，为合作伙伴实现产品的移动化、平台化、数据化的服务能力，以全面提升自身产品的竞争力，" +
          "为最终客户提供全方位的、可持续的产品销售和运营管理服务。",

    };
  },

  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .pic {
    width: 100%;
    height: 450px;
    background-image: url("../../assets/imagebox/1.png");
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .part2 {
    width: 100%;
    height: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .part2info {
    width: 60%;
    text-indent: 2em;
    color: #696969;
  }

  .partheader {
    font-size: 35px;
    font-weight: bold;
    color: #696969;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .part3 {
    width: 100%;
    height: 700px;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .part3row {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }

  .part3info {
    width: 500px;
    height: 100px;
    background-color: white;
    display: flex;
    justify-items: center;
    align-items: center;
  }

  .part3img {
    width: 80px;
    height: 80px;
    margin-left: 15px;
    margin-right: 30px;
  }

  .part4 {
    width: 100%;
    height: 650px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
  }

  .part4_row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .part4_card {
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
    width: 360px;
    height: 450px;
  //background-color: ;
    display: flex;
    flex-direction: column;
    //align-items: center;
    //justify-items: center;
    //padding: 30px;
    //justify-content: center;
  }

  .part4_card:hover {
  //box-shadow: 0 1px 6px rgba(255, 255, 255, 0.932);
    border-color: #eee;
    transition: all 0.2s ease-in-out;
    //opacity: 95%;
  //background-image: linear-gradient(to bottom right, blanchedalmond, yellowgreen);
    transform: scale(1.1);
  }

  .part4_card_img {
    width: 55px;
    height: 60px;
  }

  p {
    line-height: 2; /* 设置行距为1.5倍字体大小 */
    color: #696969;
  }
</style>
