<template>
    <!--关于我们-->
    <div class="guanyuwomen">
      <div class="guanyuwomen_row">
        <div class="gywm1">
          <span class="rexian">售前咨询</span>
          <span style="font-size: 12px; color: white; margin-top: 10px">fengtao@biweisoft.com</span>
          <el-button size="mini" type="primary" style="margin-top: 10px; color: white; font-size: 14px;margin-right: 10px">联系我们</el-button>
        </div>
        <div class="gywm2">
          <span style="font-size: 20px; margin-left: -10px">快速导航</span>
          <span style="margin-top: 20px"> </span>
          <div class="daohangdiv">
            <span class="daohang" style="font-size: 15px" @click="getTo('/solution', '2')"> 产品与解决方案</span>
            <span class="daohang" style="font-size: 15px" @click="getTo('/case', '3')"> 应用案例</span>
            <span class="daohang" style="font-size: 15px" @click="getTo('/business', '4')"> 招商代理</span>
            <span class="daohang" style="font-size: 15px" @click="getTo('/about', '5')"> 关于我们</span>
          </div>
        </div>
        <div class="gywm3">
          <span style="font-size: 20px">联系我们</span>
          <span style="margin-top: 20px"> </span>
          <span style="font-size: 15px">产品中心</span>
          <span style="font-size: 15px">地址：陕西省西咸新区沣西新城西部云谷一期E3号楼9层G-Q161号</span>
          <span style="font-size: 15px">邮箱：fengtao@biweisoft.com</span>
        </div>
        <div class="gywm4">
          <img style="width: 100px; height: 100px; margin-top: 30px" src="../assets/gywm/weixin.png">
          <span style="margin-top: 5px; margin-left: 10px; color: white">微信公众号</span>
        </div>
      </div>
      <el-divider></el-divider>

      <span style="font-size: 12px; color: white; position: relative; justify-content: center; margin-top: -15px">Copyright © 西安彼维软件科技有限公司, All Rights Reserved.</span>
    </div>
</template>

<script>
import router from "@/router";

export default {
  name: 'HelloWorld',

  data() {
    return {
      // activeIndex: '1',
      isfixTab: false,
      lastScrollTop: 0,
      navOffset: '',
      activeNavMenuIndex: "1",
      activeNavBarIndex: "1",
      part1Offset: null,
      part2Offset: null,
      part3Offset: null,
      part4Offset: null,
      imagebox: [

        {id:0,idView:require('../assets/imagebox/1.png')},
        {id:1,idView:require('../assets/imagebox/2.png')},
        {id:2,idView:require('../assets/imagebox/3.png')},
        {id:3,idView:require('../assets/imagebox/4.png')}
        //imagebox是assets下一个放图片的文件夹
      ],

      product1Info1: "TMC智慧消防云平台1",
      product1Info2: "TMC智慧消防云平台在现有的消防系统基础上，结合大数据、物联网、云计算等先进技术，通过在硬件层加入数据采集设备，" +
          "构建传感网络，将数据上传至云平台，开发针对性的业务模块，将“人防、物防、技防”结合应用于传统的消防管理和监督，实现四个消防在线。",

    };
  },


  // 监听页面滚动
  mounted () {
    window.addEventListener('scroll', this.handleScroll, true)
    this.part1Offset = document.getElementById('part1').offsetTop
    this.part2Offset = document.getElementById('part2').offsetTop
    this.part3Offset = document.getElementById('part3').offsetTop
    this.part4Offset = document.getElementById('part4').offsetTop
  },

  beforeRouteLeave (to, from, next) {
    window.removeEventListener('scroll', this.handleScroll, true)
    next()
  },

  methods: {
    getTo(path, id) {
      console.info("11111111111", path , id )
      this.$emit('update:activeIndex', id);
      router.push(path)
    },

    // navBarHandleSelect(key, keyPath) {
    //   console.log(key, keyPath);
    //   var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    //   var offsetTop = document.getElementById("part" + key).offsetTop
    //   if (key == 1) {
    //     window.scrollTo({top: offsetTop - 60, behavior: 'smooth'})
    //   } else {
    //     window.scrollTo({top: offsetTop - 110, behavior: 'smooth'})
    //   }
    //   this.activeNavBarIndex = key
    // },

    // handleScroll() {
    //   var nav = document.getElementById('navBar');
    //   if (nav.offsetTop>100) {
    //     this.navOffset = nav.offsetTop
    //   }
    //   var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    //   if (scrollTop > this.navOffset - 55) {
    //     this.isfixTab = true
    //   } else {
    //     this.isfixTab = false
    //   }
    //
    //   if (scrollTop > this.part4Offset - 185) {
    //     this.activeNavBarIndex = "4"
    //   } else if (scrollTop > this.part3Offset -185) {
    //     this.activeNavBarIndex = "3"
    //   } else if (scrollTop > this.part2Offset - 185) {
    //     this.activeNavBarIndex = "2"
    //   } else if (scrollTop > this.part1Offset - 185) {
    //     this.activeNavBarIndex = "1"
    //   }
    //   console.info("navBar = " + nav.offsetTop)
    //   console.info("scrollTop = " + scrollTop)
    //   console.info("part1 = " + this.part1Offset)
    //   console.info("part2 = " + this.part2Offset)
    //   console.info("part3 = " + this.part3Offset)
    //   console.info("part4 = " + this.part4Offset)
    //   console.info("activeIndex = " + this.activeIndex)
    // },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .guanyuwomen {
    height: 230px;
    width: 100%;
    background-color: #2c3e50;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .guanyuwomen_row {
    height: 170px;
    width: 100%;
    background-color: #2c3e50;
    display: flex;
    flex-direction: row;
  }

  .gywm1 {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-items: right;
    padding-right: 30px;
  }

  .gywm2 {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    margin-top: 30px;
  }

  .gywm3 {
    width: 25%;
    display: flex;
    flex-direction: column;
    color: white;
    margin-top: 30px;
  }

  .gywm4 {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .rexian {
    display: inline-block;
    background: url("../assets/gywm/rexian.png") no-repeat left;
    background-size: contain;
    padding-left: 30px;
    font-size: 20px;
    color: white;
    margin-top: 30px;
  }

  p {
    line-height: 2; /* 设置行距为1.5倍字体大小 */
  }

  .daohangdiv {
    display: flex;
    flex-direction: column;
    justify-items: center;
  }

  .daohang:hover {
    cursor: pointer;
    color: #1890FF;
  }
</style>
