<template>
  <div>
    <!--图片循环播-->
    <div >
      <el-carousel
          :interval="3000"
          height="450px">
        <el-carousel-item v-for="item in imagebox" :key="item.id">
          <div class="lunbotu">
            <img style="width: 100%" :src="item.idView">
            <div class="lunbotu_inline">
              <div style="margin-top: 8%;">
                <h3 style="font-size: 50px">{{ item.header }}</h3>
              </div>
              <div style="margin-top: 5%;  display: flex; justify-content: center">
                <span style="font-size: 20px;">{{ item.text }}</span>
              </div>
              <div style="margin-top: 6%; display: flex; justify-content: center">
                <el-button type="primary" style="color: white; font-size: 20px;">立即咨询</el-button>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <!--解决方案-->
    <div class="fangan">
      <div class="partheader">
        <h>产品与解决方案</h>
      </div>
      <div class="fangan-card-row" style="margin-top: 10px">
        <el-card class="fangan-box-card">
          <div class="fangan-card-column-center">
            <div>
              <img class="fangan_icon" src="../assets/anli/ICON_case.png">
            </div>
            <div class="fangan_card_title">{{ fangan1_card_title }}</div>
            <p class="fangan_card_text" style="line-height: 1.5">{{ fangan1_card_text }}</p>
            <button class="fangan_card_btn" @click="goToSolution('solution',1)">查看详情</button>
          </div>
        </el-card>
        <el-card class="fangan-box-card">
          <div class="fangan-card-column-center">
            <div>
              <img class="fangan_icon" src="../assets/anli/ICON_case.png">
            </div>
            <div class="fangan_card_title">{{ fangan2_card_title }}</div>
            <p class="fangan_card_text" style="line-height: 1.5">{{ fangan2_card_text }}</p>
            <button class="fangan_card_btn" @click="goToSolution('solution',2)">查看详情</button>
          </div>
        </el-card>
        <el-card class="fangan-box-card">
          <div class="fangan-card-column-center">
            <div>
              <img class="fangan_icon" src="../assets/anli/ICON_case.png">
            </div>
            <div class="fangan_card_title">{{ fangan3_card_title }}</div>
            <p class="fangan_card_text" style="line-height: 1.5">{{ fangan3_card_text }}</p>
            <button class="fangan_card_btn" @click="goToSolution('solution',3)">查看详情</button>
          </div>
        </el-card>
        <el-card class="fangan-box-card">
          <div class="fangan-card-column-center">
            <div>
              <img class="fangan_icon" src="../assets/anli/ICON_case.png">
            </div>
            <div class="fangan_card_title">{{ fangan4_card_title }}</div>
            <p class="fangan_card_text" style="line-height: 1.5">{{ fangan4_card_text }}</p>
            <button class="fangan_card_btn" @click="goToSolution('solution', 4)">查看详情</button>
          </div>
        </el-card>
      </div>
    </div>
    <!--应用案例-->
    <div class="anli">
      <div class="partheader">
        <h>应用案例</h>
      </div>
      <div class="anli_row">
        <div></div>
        <el-card class="anli_card">
          <img class="gray-scale-image" style="width: 400px; height: 200px; margin-top: -20px;margin-left: -20px" src="../assets/index/zhihuiyuanqu.png">
          <div style="margin-top: 30px; margin-left: 120px; font-size: 25px; color: dimgray">智慧校园</div>
          <p style="margin-top: 20px; width: 350px; color: dimgray; line-height: 1.8">
            基于国产化软硬件平台，在现有智慧校园系统上集成学校车辆智能综合管理系统，实现停车场、车辆通行、访客预约等自动化管理和分析，提高学校园区管理效率。
          </p>
          <button class="anli_card_btn" style="margin-left: 35%" @click="goToCase('case', 1)">查看详情</button>
        </el-card>
        <el-card class="anli_card">
          <img class="gray-scale-image" style="width: 400px; height: 200px; margin-top: -20px;margin-left: -20px" src="../assets/index/weixingjiankong.png">
          <div style="margin-top: 30px; margin-left: 120px; font-size: 25px; color: dimgray">工业控制</div>
          <p style="margin-top: 20px; width: 350px; color: dimgray; line-height: 1.8">
            卫星监测中心数字监测系统，实现实时监控、全局概览、全流程跟踪、任务监控, 能够对整体监测数据进行全局概览，对于重要信息和各项统计分析数据在首页进行展示。
          </p>
          <button class="anli_card_btn" style="margin-left: 35%" @click="goToCase('case', 2)">查看详情</button>
        </el-card>
        <el-card class="anli_card">
          <img class="gray-scale-image" style="width: 400px; height: 200px; margin-top: -20px;margin-left: -20px" src="../assets/index/shuzinengyuan.png">
          <div style="margin-top: 30px; margin-left: 120px; font-size: 25px; color: dimgray">数字能源</div>
          <p style="margin-top: 20px; width: 350px; color: dimgray; line-height: 1.8">
            通过数据集成平台+主数据管理平台搜集并整理基础数据，构建全景化、动态化、数字化的数字化大屏，直观呈现全领域的运营情况，大幅度提升了运营管理水平。
          </p>
          <button class="anli_card_btn" style="margin-left: 35%" @click="goToCase('case', 3)">查看详情</button>
        </el-card>
      </div>
    </div>
    <!--合作伙伴-->
    <div class="hezuohuoban">
      <div class="partheader">
        <h>合作伙伴</h>
      </div>
      <div class="hzhb_row">
        <div class="hzhb_img">
          <img class="hzhb_png" style="width: 120px; height: 80px" src="../assets/hzhb/hezuohuopan1.png">
        </div>
        <div class="hzhb_img">
          <img class="hzhb_png" style="width: 200px; height: 70px" src="../assets/hzhb/hezuohuopan2.png">
        </div>
        <div class="hzhb_img">
          <img class="hzhb_png" style="width: 180px" src="../assets/hzhb/hezuohuopan3.png">
        </div>
        <div class="hzhb_img">
          <img class="hzhb_png" style="width: 150px; height: 100px" src="../assets/hzhb/hezuohuoban4.png">
        </div>
        <div class="hzhb_img">
          <img class="hzhb_png" src="../assets/hzhb/hezuohuoban5.png">
        </div>
        <div class="hzhb_img">
          <img class="hzhb_png" style="width: 120px; height: 80px" src="../assets/hzhb/hezuohuoban6.png">
        </div>
        <div class="hzhb_img">
          <img class="hzhb_png" src="../assets/hzhb/hezuohuoban7.png">
        </div>
        <div class="hzhb_img">
          <img class="hzhb_png" style="width: 180px" src="../assets/hzhb/hezuohuoban8.png">
        </div>
        <div class="hzhb_img">
          <img class="hzhb_png" style="width: 250px" src="../assets/hzhb/hezuohuoban9.png">
        </div>
      </div>
      <div class="hzhb_row">

      </div>
    </div>
    <BottomDiv></BottomDiv>
  </div>
</template>

<script>
import BottomDiv from "@/components/BottomDiv.vue";
import router from "@/router";

export default {
  name: 'HelloWorld',
  components: { BottomDiv },
  data() {
    return {
      imagebox: [

        {
          id:0,
          idView:require('../assets/imagebox/1.png'),
          header: '为客户提供软件及数字化技术服务和产品',
          text: '愿成为数字技术服务领导企业、各行业客户数字化转型可信赖的合作伙伴'
        },
        {
          id:1,
          idView:require('../assets/imagebox/2.png'),
          header: '助力千行百业迈入数字化、智能化新时代',
          text: '愿成为数字技术服务领导企业、各行业客户数字化转型可信赖的合作伙伴'
        },
        {
          id:2,
          idView:require('../assets/imagebox/3.png'),
          header: '为客户提供软件及数字化技术服务和产品',
          text: '愿为数字技术服务领导企业、各行业客户数字化转型可信赖的合作伙伴'
        },
        {
          id:3,
          idView:require('../assets/imagebox/4.png'),
          header: '助力千行百业迈入数字化、智能化新时代',
          text: '愿为数字技术服务领导企业、各行业客户数字化转型可信赖的合作伙伴'
        }
      ],

      fangan1_card_title: "国产化全栈服务",
      fangan1_card_text: "彼维软件以行业客户为主要服务群体，围绕专业服务能力和服务连续性，构建了测试、运维、集成、软硬件适配服务能力。具备从软件及技术服务、解决方案，到创新及数字化转型的综合性服务体系。",

      fangan2_card_title: "国产化AI实训",
      fangan2_card_text: "对硬件设施进行虚拟化，形成容器化资源池，为AI实验提供IT资源（计算、存储和算力）管理。支持云资源分配，配置镜像，云资源启动、释放、备份，管理虚拟机，管理数据集，云资源监控等功能。",

      fangan3_card_title: "国产化医共体",
      fangan3_card_text: "通过信息交换服务总线、数据采集、数据中心三大核心模块实现县域医疗机构和乡镇卫生院的系统数据互联互通，支撑智慧医疗加速落地。",

      fangan4_card_title: "智慧园区",
      fangan4_card_text: "以“大数据、云服务、物联网、人工智能、数字孪生”等先进技术为依托、充分整合各业务系统数据从数据采集、数据分析、数据治理、数字孪生等核心关键技术，实现了智慧化管理，对内实现“数据一个源、  空间全要素一张图、业务一条线”，对外实现了内外服务资源和服务需求的链接，打造了“共享化、智能化、协同化、全局化”的智慧园区大脑。",

    };
  },

  created() {
    this.activeIndex = "/index"
  },

  methods: {
    goToSolution(name, id) {
      // this.$emit('update:activeIndex', '2');
      // this.$emit('select', '/solution1')
      // router.push("/solution1")
      this.$router.push({
        name: name,
        params: { index: id }
      })
    },

    goToCase(path, index) {
      // router.push("/case")
      this.$router.push({
        name: path,
        params: { index: index }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .lunbotu {
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .lunbotu img {
    width: 100%; /* 或根据需要设置具体宽度 */
    height: 450px; /* 保持图片比例 */
  }

  .lunbotu .lunbotu_inline {
    position: absolute;
    color: white;
      /* 根据需要调整位置 */
  }

  .product {
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    background-color: snow;
  }

  .btn_row {
    display: flex;
    flex-direction: row;
    justify-items: center;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .btn_group {
    width: 300px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }
  .menu {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;
    align-items: center;
    max-width: 100px;
    margin: 0 auto;
    padding: 0;
    -webkit-transform: translateY(34.375px);
    transform: translateY(34.375px);
    width: 300px;
    margin-left: 100px;
    margin-right: 100px;
  }
  .menu-cell {
    flex: 0 1 150px;
    width: 150px;
    height: 50px;
    margin: 30px 5.5px 25px;
    position: relative;
    padding: 0.5em;
    text-align: center;
    background: #0a7993;
    z-index: 1;
  }
  .menu-cell::before {
    background: whitesmoke;
    -webkit-transform: scale(1.055);
    transform: scale(1.055);
  }
  .menu-cell::after {
    background: whitesmoke;
    opacity: 0.5;
    transition: opacity 350ms;
  }
  .menu-cell::before, .menu-cell::after {
    content: '';
  }
  .menu-cell:hover::before {
    background: lightblue;
    -webkit-transform: scale(1.055);
    transform: scale(1.055);
  }
  .menu-cell:hover::after {
    opacity: 0.2;
    transition: opacity 350ms;
  }
  .menu__placeholder {
    display: none;
    opacity: 0;
    width: 250px;
    margin: 0 12.5px;
  }
  .menu-cell__image {
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    border-style: none;
  }
  .menu-cell__image{
    width: 40px;
    height: 40px;
  }
  .menu-cell__title {
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    word-break: break-word;
    text-transform: uppercase;
    color: #000;
    //font-weight: 700;
    font-size: 12px;
    transition: opacity 350ms;
  }
  .menu-cell::before, .menu-cell::after{
    top: -50%;
    left: 0;
    width: 100%;
    height: 200%;
    display: block;
    position: absolute;
    -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    z-index: -1;
  }

  .fangan {
    height: 480px;
    color: black;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .box-card {
    margin-top: 30px;
    margin-left: 40px;
    margin-right: 40px;
    width: 250px;
    height: 330px;
    //background-color: ;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .box-card:hover {
    //box-shadow: 0 1px 6px rgba(255, 255, 255, 0.932);
    border-color: #eee;
    transition: all 0.2s ease-in-out;
    opacity: 95%;
    //background-image: linear-gradient(to bottom right, blanchedalmond, yellowgreen);
    transform: translateY(-6px);
  }

  .anli {
    height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .anli_row {
    display: flex;
    flex-direction: row;
  }

  .anli_card {
    margin-top: 30px;
    width: 400px;
    height: 500px;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .anli_card:hover {
    transition: all 0.5s;
    transform: scale(1.1);
    z-index: 1;
    .gray-scale-image {
      filter: grayscale(5%);
    }
  }

  .gray-scale-image {
    position: relative;
    filter: grayscale(100%);
    transition: filter 0.3s ease;
  }

  .gray-scale-image:hover {
    filter: none;
  }

  .anli_card_btn {
    width: 100px;
    height: 35px;
    margin-top: 20px;
    color: dodgerblue;
    border: 1px solid dodgerblue;
    background-color: white;
    cursor: pointer;
  }

  .anli_card_btn:hover {
    width: 100px;
    height: 35px;
    margin-top: 20px;
    color: white;
    border: 1px solid dodgerblue;
    background-color: dodgerblue;
    cursor: pointer;
  }

  .hezuohuoban {
    height: 250px;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .hzhb_row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .hzhb_img {
    //width: 80px;
    //height: 50px;
    margin-top: 30px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .hzhb_png {
    width: 150px;
    height: 50px;
  }

  .hzhb_img:hover {
    transform: scale(1.1);
  }


  .fangan {
    height: 500px;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .fangan-card-row {
    height: 350px;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .fangan-box-card {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    width: 250px;
    height: 330px;
  //background-color: ;
    display: flex;
    flex-direction: column;
    align-items: center;
  //justify-content: center;
  }

  .fangan-box-card:hover {
  //box-shadow: 0 1px 6px rgba(255, 255, 255, 0.932);
    border-color: #eee;
    transition: all 0.2s ease-in-out;
    opacity: 95%;
  //background-image: linear-gradient(to bottom right, blanchedalmond, yellowgreen);
    transform: translateY(-6px);
  }

  .fangan-card-column-center {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .fangan_card_title {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: dimgray;
  }

  .fangan_card_text {
    margin-top: 20px;
    width: 100%;
    height: 40%;
    color: dimgrey;
    line-height: 1;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .fangan_card_btn {
    width: 100px;
    height: 35px;
    margin-top: 20px;
    color: dodgerblue;
    border: 1px solid dodgerblue;
    background-color: white;
    cursor: pointer;
  }

  .fangan_card_btn:hover {
    width: 100px;
    height: 35px;
    margin-top: 20px;
    color: white;
    border: 1px solid dodgerblue;
    background-color: dodgerblue;
    cursor: pointer;
  }

  .fangan_icon {
    width: 50px;
    height: 55px;
  }

</style>
