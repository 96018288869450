<template>
  <div >
    <!--轮播图片-->
    <div >
      <el-carousel
          :interval="4000"
          height="450px">
        <el-carousel-item>
          <div class="background_img">
            <div style="width: 70%;">
              <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
                <div style="margin-left: 30%">
                  <h3 style="font-size: 35px; color: white;">{{ solution1Info1 }}</h3>
                </div>
                <div style="width: 700px; margin-left: 30%">
                  <p style="font-size: 20px; color: white; line-height: 1">{{ solution1Info2 }}</p>
                  <p style="font-size: 15px; color: white; line-height: 1">{{ solution1Info3 }}</p>
                  <p style="font-size: 15px; color: white; line-height: 1">{{ solution1Info4 }}</p>
                  <p style="font-size: 15px; color: white; line-height: 1">{{ solution1Info5 }}</p>
                  <p style="font-size: 15px; color: white; line-height: 1">{{ solution1Info6 }}</p>
                  <p style="font-size: 15px; color: white; line-height: 1">{{ solution1Info7 }}</p>
                </div>
              </div>
            </div>

            <div style="width: 30%; display: flex; flex-direction: column;">
              <img style="width: 60px; height: 70px; margin-top: 100px; margin-left: 150px" src="../../assets/product/good1.png">
              <img style="width: 380px; height: 200px; margin-top: -150px" src="../../assets/product/bud2.png">
              <img style="width: 380px; height: 200px; margin-top: -100px" src="../../assets/product/case3.png">
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="background_img">
            <div style="width: 70%;">
              <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
                <div style="margin-left: 30%">
                  <h3 style="font-size: 35px; color: white;">{{ solution2Info1 }}</h3>
                </div>
                <div style="width: 700px; margin-left: 30%">
                  <p style="font-size: 20px; color: white; line-height: 1">{{ solution2Info2 }}</p>
                  <p style="font-size: 15px; color: white; line-height: 1">{{ solution2Info3 }}</p>
                  <p style="font-size: 15px; color: white; line-height: 1">{{ solution2Info4 }}</p>
                  <p style="font-size: 15px; color: white; line-height: 1">{{ solution2Info5 }}</p>
                  <p style="font-size: 15px; color: white; line-height: 1">{{ solution2Info6 }}</p>
                  <p style="font-size: 15px; color: white; line-height: 1">{{ solution2Info7 }}</p>
                </div>
              </div>
            </div>

            <div style="width: 30%; display: flex; flex-direction: column;">
              <img style="width: 60px; height: 70px; margin-top: 100px; margin-left: 150px" src="../../assets/product/good1.png">
              <img style="width: 380px; height: 200px; margin-top: -150px" src="../../assets/product/bud2.png">
              <img style="width: 380px; height: 200px; margin-top: -100px" src="../../assets/product/case3.png">
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="background_img">
            <div style="width: 70%;">
              <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
                <div style="margin-left: 30%">
                  <h3 style="font-size: 35px; color: white;">{{ solution3Info1 }}</h3>
                </div>
                <div style="width: 700px; margin-left: 30%">
                  <p style="font-size: 20px; color: white; line-height: 1">{{ solution3Info2 }}</p>
                  <p style="font-size: 15px; color: white; line-height: 1">{{ solution3Info3 }}</p>
                  <p style="font-size: 15px; color: white; line-height: 1">{{ solution3Info4 }}</p>
                  <p style="font-size: 15px; color: white; line-height: 1">{{ solution3Info5 }}</p>
                  <p style="font-size: 15px; color: white; line-height: 1">{{ solution3Info6 }}</p>
                  <p style="font-size: 15px; color: white; line-height: 1">{{ solution3Info7 }}</p>
                </div>
              </div>
            </div>

            <div style="width: 30%; display: flex; flex-direction: column;">
              <img style="width: 60px; height: 70px; margin-top: 100px; margin-left: 150px" src="../../assets/product/good1.png">
              <img style="width: 380px; height: 200px; margin-top: -150px" src="../../assets/product/bud2.png">
              <img style="width: 380px; height: 200px; margin-top: -100px" src="../../assets/product/case3.png">
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div :class='{ fixedNavbar: isfixTab }' id="navBar">
      <el-menu
          :default-active="activeNavBarIndex"
          mode="horizontal"
          @select="navBarHandleSelect"
          background-color="#ffffff"
          text-color="#000000"
          style="width: 100%; padding-left: 30%;"
          active-text-color="#0000ff">
        <el-menu-item index="1">{{ solutionTitle1 }}</el-menu-item>
        <el-menu-item index="2">{{ solutionTitle2 }}</el-menu-item>
        <el-menu-item index="3">{{ solutionTitle3 }}</el-menu-item>
        <el-menu-item index="4">{{ solutionTitle4 }}</el-menu-item>
      </el-menu>
    </div>

    <div class="partn" id="part1">
      <div  class="part_info">
        <span>{{ solutionTitle1 }}</span>
      </div>
      <div class="img_div">
        <img class="part_img" src="../../assets/product/1guochanhuaquanzhanfuwu.png">
      </div>
    </div>
    <div class="part2n" id="part2">
      <div class="part_info">
        <span>{{ solutionTitle2 }}</span>
      </div>
      <div class="img_div">
        <img class="part_img" src="../../assets/product/2suanlipingtai.png">
      </div>
    </div>
    <div class="partn" id="part3">
      <div class="part_info">
        <span>{{ solutionTitle3 }}</span>
      </div>
      <div class="img_div">
        <img class="part_img" src="../../assets/product/3yigongti.png">
      </div>
    </div>
    <div class="part2n" id="part4">
      <div class="part_info">
        <span>{{ solutionTitle4 }}</span>
      </div>
      <div class="img_div">
        <img class="part_img" src="../../assets/product/4zhihuiyuanqu.png" alt="">
      </div>
    </div>
    <!--底部关于我们-->
    <BottomDiv></BottomDiv>
  </div>
</template>

<script>
import BottomDiv from '@/components/BottomDiv.vue'
export default {
  name: 'HelloWorld',

  components: { BottomDiv },

  data() {
    return {
      isfixTab: false,
      activeIndex: "1",
      activeNavBarIndex: "1",
      part1Offset: null,
      part2Offset: null,
      part3Offset: null,
      part4Offset: null,
      imagebox: [

        {id:0,idView:require('../../assets/imagebox/1.png')},
        {id:1,idView:require('../../assets/imagebox/2.png')},
        {id:2,idView:require('../../assets/imagebox/3.png')},
        {id:3,idView:require('../../assets/imagebox/4.png')}
        //imagebox是assets下一个放图片的文件夹
      ],

      solution1Info1: "通用技术服务",
      solution1Info2: "为客户提供信息技术服务，助力客户打造面向未来的行业生态系统和业务模式，提高经营绩效。",
      solution1Info3: " • 系统设计与开发;    ",
      solution1Info4: " • 产品研发;",
      solution1Info5: " • 开发测试;",
      solution1Info6: " • 运营维护;",
      solution1Info7: " • 技术服务外包;",

      solution2Info1: "国产化服务&解决方案",
      solution2Info2: "为客户提供国产化适配方案，支撑客户业务系统国产化迁移实施，国产化系统运维，确保业务系统安全可靠，平稳运行。",
      solution2Info3: " • 国产化咨询",
      solution2Info4: " • 国产化医共体解决方案",
      solution2Info5: " • 国产化AI算力平台",
      solution2Info6: " • 适配方案",
      solution2Info7: " • 迁移&运维",

      solution3Info1: "智慧园区解决方案",
      solution3Info2: "为客户提供智慧园区解决方案及服务，促进工业园区、医院园区、校园园区等各系统间的数据共享，实现业务协同和决策支持，提高园区管理者的决策能力。",
      solution3Info3: " • 智慧医疗",
      solution3Info4: " • 智慧校园",
      solution3Info5: " • 物联网/大数据",
      solution3Info6: " • 智慧党建",
      solution3Info7: " • 数字能源",

      solutionTitle1: '国产化全栈服务解决方案',
      solutionTitle2: '国产化AI实训算力平台解决方案',
      solutionTitle3: '国产化医共体解决方案',
      solutionTitle4: '智慧园区解决方案',

    };
  },

  created() {

  },

  // 监听页面滚动
  mounted () {
    window.addEventListener('scroll', this.handleScroll, true)
    this.part1Offset = document.getElementById('part1').offsetTop
    this.part2Offset = document.getElementById('part2').offsetTop
    this.part3Offset = document.getElementById('part3').offsetTop
    this.part4Offset = document.getElementById('part4').offsetTop
    this.activeIndex = 2

    if (this.$route.params.index !== undefined) {
      console.log("route.params.index = ", this.$route.params.index)
      console.log("this.part1Offset = ", this.part1Offset)
      console.log("this.part2Offset = ", this.part2Offset)
      console.log("this.part3Offset = ", this.part3Offset)
      console.log("this.part4Offset = ", this.part4Offset)
      let id = this.$route.params.index
      if (id === 1) {
        window.scrollTo({top: this.part1Offset - 130, behavior: 'smooth'})
      } else if (id === 2) {
        window.scrollTo({top: this.part2Offset - 150, behavior: 'smooth'})
      } else if (id === 3) {
        window.scrollTo({top: this.part3Offset - 150, behavior: 'smooth'})
      } else if (id === 4) {
        window.scrollTo({top: this.part4Offset - 150, behavior: 'smooth'})
      }
    }
  },

  beforeRouteLeave (to, from, next) {
    window.removeEventListener('scroll', this.handleScroll, true)
    next()
  },

  methods: {

    navBarHandleSelect(key, keyPath) {
      console.log(key, keyPath);
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      var offsetTop = document.getElementById("part" + key).offsetTop
      window.scrollTo({top: offsetTop - 110, behavior: 'smooth'})
      // if (key === 1) {
      //   window.scrollTo({top: offsetTop - 110, behavior: 'smooth'})
      // } else {
      //   window.scrollTo({top: offsetTop - 110, behavior: 'smooth'})
      // }
      this.activeNavBarIndex = key
    },

    handleScroll() {
      var nav = document.getElementById('navBar');
      if (nav.offsetTop>100) {
        this.navOffset = nav.offsetTop
      }
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > this.navOffset - 55) {
        this.isfixTab = true
      } else {
        this.isfixTab = false
      }

      if (scrollTop > this.part4Offset - 185) {
        this.activeNavBarIndex = "4"
      } else if (scrollTop > this.part3Offset -185) {
        this.activeNavBarIndex = "3"
      } else if (scrollTop > this.part2Offset - 185) {
        this.activeNavBarIndex = "2"
      } else if (scrollTop > this.part1Offset - 185) {
        this.activeNavBarIndex = "1"
      }
      console.info("navBar = " + nav.offsetTop)
      console.info("scrollTop = " + scrollTop)
      console.info("part1 = " + this.part1Offset)
      console.info("part2 = " + this.part2Offset)
      console.info("part3 = " + this.part3Offset)
      console.info("part4 = " + this.part4Offset)
      console.info("activeIndex = " + this.activeIndex)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .background_img {
    width: 100%;
    height: 500px;
    background-image: url("../../assets/imagebox/1.png");
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  p {
    line-height: 2; /* 设置行距为1.5倍字体大小 */
  }

  .partn {
    width: 100%;
    height: 800px;
    background-color: #eeeeee;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .part2n {
    width: 100%;
    height: 800px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .part_info {
    //width: 100%;
    //height: 300px;
    margin-top: 50px;
    font-size: 40px;
    font-weight: bold;
  }

  .img_div {
    width: 80%;
    //height: 1800px;
    overflow: hidden;
  }

  .img_div .part_img {
    //width: 100%;
    //height: 800px;
    //margin-top: 25px;
    width: 100%;
    height: 95%;
    object-fit: fill;
  }

  .fixedNavbar{
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    border-top: 0.05rem solid #f5f5f5;
    border-bottom: 0.05rem solid #f5f5f5;
    background: #f5f5f5;
  }

</style>
