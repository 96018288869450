<template>
  <div>
    <!--图片-->
    <div class="background_img">
      <div style="width: 70%;">
        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
          <div style="margin-left: 30%">
            <h3 style="font-size: 35px; color: white;">{{ caseInfo1 }}</h3>
          </div>
          <div style="width: 700px; margin-left: 30%">
            <div class="text-line">
              <h3 style="width: 70px; color: orangered">{{ caseInfo2_head }}</h3>
              <p style="width: 600px; font-size: 15px; color: white; line-height: 1.5">{{ caseInfo2 }}</p>
            </div>
            <div class="text-line">
              <h3 style="width: 70px; color: orangered">{{ caseInfo3_head }}</h3>
              <p style="width: 600px; font-size: 15px; color: white; line-height: 1.5">{{ caseInfo3 }}</p>
            </div>
            <div class="text-line">
              <h3 style="width: 70px; color: orangered">{{ caseInfo4_head }}</h3>
              <p style="width: 600px; font-size: 15px; color: white; line-height: 1.5;">{{ caseInfo4 }}</p>
            </div>
            <div class="text-line">
              <h3 style="width: 70px; color: orangered">{{ caseInfo5_head }}</h3>
              <p style="width: 600px; font-size: 15px; color: white; line-height: 1.5">{{ caseInfo5 }}</p>
            </div>
          </div>
        </div>
      </div>

      <div style="width: 30%; display: flex; flex-direction: column;">
        <img style="width: 60px; height: 70px; margin-top: 100px; margin-left: 150px" src="../../assets/product/good1.png">
        <img style="width: 380px; height: 200px; margin-top: -150px" src="../../assets/product/bud2.png">
        <img style="width: 380px; height: 200px; margin-top: -100px" src="../../assets/product/case3.png">
      </div>
    </div>



    <div class="anli">
      <div>
        <h3 style="font-size: 40px">案例总览</h3>
      </div>
      <div class="card-row" style="margin-top: 10px">
        <el-card class="box-card">
          <div class="card-column-center">
            <div>
              <img class="case_icon" src="../../assets/anli/ICON_case.png">
            </div>
            <div class="case_card_title">{{ case1_card_title }}</div>
            <p class="case_card_text" style="line-height: 1.5">{{ case1_card_text }}</p>
            <button class="case_card_btn" @click="goTo(part1Offset)">查看详情</button>
          </div>
        </el-card>
        <el-card class="box-card">
          <div class="card-column-center">
            <div>
              <img class="case_icon" src="../../assets/anli/ICON_case.png">
            </div>
            <div class="case_card_title">{{ case2_card_title }}</div>
            <p class="case_card_text" style="line-height: 1.5">{{ case2_card_text }}</p>
            <button class="case_card_btn" @click="goTo(part2Offset)">查看详情</button>
          </div>
        </el-card>
        <el-card class="box-card">
          <div class="card-column-center">
            <div>
              <img class="case_icon" src="../../assets/anli/ICON_case.png">
            </div>
            <div class="case_card_title">{{ case3_card_title }}</div>
            <p class="case_card_text" style="line-height: 1.5">{{ case3_card_text }}</p>
            <button class="case_card_btn" @click="goTo(part3Offset)">查看详情</button>
          </div>
        </el-card>
        <el-card class="box-card">
          <div class="card-column-center">
            <div>
              <img class="case_icon" src="../../assets/anli/ICON_case.png">
            </div>
            <div class="case_card_title">{{ case4_card_title }}</div>
            <p class="case_card_text" style="line-height: 1.5">{{ case4_card_text }}</p>
            <button class="case_card_btn" @click="goTo(part4Offset)">查看详情</button>
          </div>
        </el-card>
        <el-card class="box-card">
          <div class="card-column-center">
            <div>
              <img class="case_icon" src="../../assets/anli/ICON_case.png">
            </div>
            <div class="case_card_title">{{ case5_card_title }}</div>
            <p class="case_card_text" style="line-height: 1.5">{{ case5_card_text }}</p>
            <button class="case_card_btn" @click="goTo(part5Offset)">查看详情</button>
          </div>
        </el-card>
      </div>
    </div>

    <div class="partn" id="part1">
      <div  class="part_info">
        <span>{{ anliTitle1 }}</span>
      </div>
      <div class="img_div">
        <img class="part_img" src="../../assets/anli/case1.png">
      </div>
    </div>
    <div class="part2n" id="part2">
      <div class="part_info">
        <span>{{ anliTitle2 }}</span>
      </div>
      <div class="img_div">
        <img class="part_img" src="../../assets/anli/case2.png">
      </div>
    </div>
    <div class="partn" id="part3">
      <div  class="part_info">
        <span>{{ anliTitle3 }}</span>
      </div>
      <div class="img_div">
        <img class="part_img" src="../../assets/anli/case3.png">
      </div>
    </div>
    <div class="part2n" id="part4">
      <div class="part_info">
        <span>{{ anliTitle4 }}</span>
      </div>
      <div class="img_div">
        <img class="part_img" src="../../assets/anli/case4.png">
      </div>
    </div>
    <div class="partn" id="part5">
      <div  class="part_info">
        <span>{{ anliTitle5 }}</span>
      </div>
      <div class="img_div">
        <img class="part_img" src="../../assets/anli/case5.png">
      </div>
    </div>
    <bottom-div></bottom-div>
  </div>
</template>

<script>
import BottomDiv from "@/components/BottomDiv.vue";

export default {
  name: 'HelloWorld',
  components: { BottomDiv },
  data() {
    return {
      part1Offset: null,
      part2Offset: null,
      part3Offset: null,
      part4Offset: null,
      part5Offset: null,
      caseInfo1: "行业布局",
      caseInfo2_head: "教育：",
      caseInfo2: "AI算力平台：联合西安航投集团旗下公司打造了国产化AI实训算力平台，为高校AI实验室提供一站式算力平台安装部署等。" +
          "智慧校园：智慧校园解决方案在西安邮电、西安工业等高校成功落地运行。",
      caseInfo3_head: "医疗：",
      caseInfo3: "国产化医共体：医共体解决方案在西乡县成功试点。",
      caseInfo4_head: "能源：",
      caseInfo4: "为陕煤集团、延长石油等能源客户提供数字能源解决方案，助力能源业务数字化转型。",
      caseInfo5_head: "其他：",
      caseInfo5: "立讯：为立讯提供人力累计超过50+人。航空：助力西安卫星测控中心打造数字监测系统。军工：入驻西安兵器基地，为园区企业提供信息技术服务。",

      case1_card_title: "智慧校园",
      case1_card_text: "基于国产化软硬件平台，在现有智慧校园系统上集成学校车辆智能综合管理系统，实现停车场、车辆通行、访客预约等自动化管理和分析，提高学校园区管理效率。",
      case2_card_title: "智慧社区",
      case2_card_text: "基于国产化软硬件平台，打造智慧社区软件平台，接入物联网设备，实现一体化管理，大数据分析支撑物业决策，提高社区运营管理效率。",
      case3_card_title: "数字能源",
      case3_card_text: "通过数据集成平台+主数据管理平台搜集并整理基础数据，构建全景化、动态化、数字化的数字化大屏，直观呈现全领域的运营情况，大幅度提升了运营管理水平.",
      case4_card_title: "工业控制",
      case4_card_text: "卫星监测中心数字监测系统，实现实时监控、全局概览、全流程跟踪、任务监控, 能够自动进行告警和页面提示。提高人工分析的效率。",
      case5_card_title: "国产化",
      case5_card_text: "某电力项目正式搬迁依然属于云场景下的原地迁移，覆盖范围广，包含了蒙东内网的云上/云下环境，北京外网云下、北京内网云下等多个场景。",

      anliTitle1: "高校园区车辆管理",
      anliTitle2: "园区运营",
      anliTitle3: "数据中台优化",
      anliTitle4: "卫星监测中心数字监测系统",
      anliTitle5: "电力公司操作系统搬迁",


    };
  },


  mounted () {
    window.addEventListener('scroll', this.handleScroll, true)
    this.part1Offset = document.getElementById('part1').offsetTop
    this.part2Offset = document.getElementById('part2').offsetTop
    this.part3Offset = document.getElementById('part3').offsetTop
    this.part4Offset = document.getElementById('part4').offsetTop
    this.part5Offset = document.getElementById('part5').offsetTop

    if (this.$route.params.index !== undefined) {
      console.log("route.params.index = ", this.$route.params.index)
      console.log("this.part1Offset = ", this.part1Offset)
      console.log("this.part2Offset = ", this.part2Offset)
      console.log("this.part3Offset = ", this.part3Offset)
      console.log("this.part4Offset = ", this.part4Offset)
      let id = this.$route.params.index
      if (id === 1) {
        window.scrollTo({top: this.part1Offset - 50, behavior: 'smooth'})
      } else if (id === 2) {
        window.scrollTo({top: this.part4Offset - 50, behavior: 'smooth'})
      } else if (id === 3) {
        window.scrollTo({top: this.part3Offset - 50, behavior: 'smooth'})
      }
    }
  },

  beforeRouteLeave (to, from, next) {
    window.removeEventListener('scroll', this.handleScroll, true)
    next()
  },

  methods: {
    goTo(Offset) {
      window.scrollTo({top: Offset - 50, behavior: 'smooth'})
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .background_img {
    width: 100%;
    height: 450px;
    background-image: url("../../assets/imagebox/1.png");
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  p {
    line-height: 2; /* 设置行距为1.5倍字体大小 */
  }

  .anli {
    height: 500px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card-row {
    height: 350px;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .box-card {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    width: 250px;
    height: 330px;
  //background-color: ;
    display: flex;
    flex-direction: column;
    align-items: center;
    //justify-content: center;
  }

  .box-card:hover {
  //box-shadow: 0 1px 6px rgba(255, 255, 255, 0.932);
    border-color: #eee;
    transition: all 0.2s ease-in-out;
    opacity: 95%;
  //background-image: linear-gradient(to bottom right, blanchedalmond, yellowgreen);
    transform: translateY(-6px);
  }

  .partn {
    width: 100%;
    height: 800px;
    background-color: #eeeeee;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .part2n {
    width: 100%;
    height: 800px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .part_info {
  //width: 100%;
  //height: 300px;
    margin-top: 50px;
    font-size: 40px;
    font-weight: bold;
  }

  .img_div {
    width: 80%;
  //height: 1800px;
    overflow: hidden;
  }

  .img_div .part_img {
  //width: 100%;
  //height: 800px;
  //margin-top: 25px;
    width: 100%;
    height: 95%;
    object-fit: fill;
  }

  .card-column-center {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .case_icon {
    width: 50px;
    height: 55px;
  }

  .case_card_title {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: dimgray;
  }

  .case_card_text {
    margin-top: 20px;
    width: 100%;
    height: 40%;
    color: dimgrey;
    line-height: 1;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .case_card_btn {
    width: 100px;
    height: 35px;
    margin-top: 20px;
    color: dodgerblue;
    border: 1px solid dodgerblue;
    background-color: white;
    cursor: pointer;
  }

  .case_card_btn:hover {
    width: 100px;
    height: 35px;
    margin-top: 20px;
    color: white;
    border: 1px solid dodgerblue;
    background-color: dodgerblue;
    cursor: pointer;
  }

  .text-line {
    display: flex;
    flex-direction: row;
    margin-top: -20px;
  }

</style>
