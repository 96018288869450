<template>
  <div class="app">
<!--    style="position:absolute;left:0;right:0;top:0;bottom:0;"-->
    <el-container>
      <!-- 顶部栏 -->
      <el-header width=100% height="60px" style="padding:0px;">
        <div class="topbox">
          <div class="protalName">
            <img src="../assets/logo.png" style="width: 40px; height: 28px; margin-right: 5px">
            <span style="font-family: 微软雅黑; font-size: 20px">西安彼维软件科技有限公司</span>
          </div>
          <div class="menu">
            <el-menu
                :default-active="activeIndex"
                :activeIndex.async="activeIndex"
                class="el-menu-demo"
                mode="horizontal"
                @select="handleSelect"
                background-color="#000000"
                text-color="#ffffff"
                router
                style="height: 60px"
                active-text-color="#0000ff">
              <el-menu-item index="/index">首页</el-menu-item>
              <el-menu-item index="/solution">产品与解决方案</el-menu-item>
              <el-menu-item index="/case">应用案例</el-menu-item>
              <el-menu-item index="/business">招商代理</el-menu-item>
              <el-menu-item index="/about">关于我们</el-menu-item>
            </el-menu>
          </div>
        </div>
      </el-header>

      <!-- 主页面-->
      <el-main style="height: 100%;padding: 0 !important;">
        <router-view style="height:100%"/>
      </el-main>
    </el-container>
  </div>
</template>

<script>

import BottomDiv from "@/components/BottomDiv.vue";
export default {
  name: 'HelloWorld',
  components: { BottomDiv },
  data() {
    return {
      activeIndex: this.$route.path,
      imagebox: [
        {id:0,idView:require('../assets/imagebox/1.png')},
        {id:1,idView:require('../assets/imagebox/2.png')},
        {id:2,idView:require('../assets/imagebox/3.png')},
        {id:3,idView:require('../assets/imagebox/4.png')}
      ],

    };
  },

  watch:{
    '$route'(to,from){
      this.activeIndex=to.path
    }
  },


  methods: {
    handleSelect(key, keyPath) {
      console.log("888888888 key = ", key);
      console.log("888888888 keyPath = ", keyPath);
      this.activeIndex = key
    },
    getActiveIndex(index) {
      console.log("999999999 index = ", index)
      this.activeIndex = index
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .topbox {
    position: fixed;
    width: 100%;
    //min-width: 1200px;
    height: 60px;
    background-color: #000000;
    z-index: 600;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .protalName {
    width: 35%;
    height: 23px;
    font-size: 20px;
    color: white;
    //font-family: 微软雅黑;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu {
    //width: 50%;
    //margin-left: 30px;
    //background-color: black;
    //border-color: black;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  /*背景透明*/
  /*.el-menu-demo {
    background: transparent;
  }*/

  /*去除自带的底部border*/
  /*.el-menu.el-menu--horizontal {
    border: none;
  }*/

  /*更改鼠标划过背景色字体色及borer显示*/
  /*.el-menu--horizontal>>>.el-menu-item:not(.is-disabled):hover {
    background: transparent !important;
    color: #fff;
    border-bottom: 1px solid #fff !important;
  }*/

  /*主菜单样式*/
  /*>>>.el-submenu__title{

  }*/
  /*主菜单悬浮样式*/
  /*/deep/.el-submenu__title:hover {
    color:white!important;
    background: #1890FF!important;
  }*/
  /*子菜单样式*/
  /*/deep/.el-menu-item{
    background: #283847!important;
  }*/
  /*子菜单悬浮样式*/
  /deep/.el-menu-item:hover {
    color:white!important;
    background: red!important;
  }


  /* 覆盖菜单背景色 */
  /*.el-menu--horizontal {
    background-color: #334157;
  }*/

  /* 覆盖选中菜单项的背景色 */
  /*.el-menu-item.is-active {
    background-color: #263445;
  }*/

  /* 覆盖菜单项的文本颜色 */
  /*.el-menu--horizontal .el-menu-item {
    color: #fff; /* 菜单横向模式下的文本颜色
  }*/

  /* 覆盖选中菜单项的文本颜色 */
  .el-menu--horizontal .el-menu-item.is-active {
    color: #ffd04b;
  }


  /*设置一级菜单和二级菜单的背景颜色*/
  .el-sub-menu .el-menu-item {
    //background-color: RGB(38,87,227) !important;
  }
  /*设置选鼠标指针浮动在一级菜单的设置*/
  .el-menu-item:hover{
    background-color: #1890FF !important;
    //border-bottom: 2px solid white !important;
  }

  /*设置当前被选中的一级菜单*/
  .el-menu-item.is-active {
    background: black !important;
  }
  .el-sub-menu:hover{
    background-color: red !important;
  }
  /*.el-sub-menu__title:focus, .el-sub-menu__title:hover{*/
  /*  background-color: #1890FF !important;*/
  /*}*/

</style>
