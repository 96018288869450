<template>
  <div>
    <!--图片-->
    <div class="pic">
      <div style="margin-top: 100px">
        <h3 style="font-size: 45px; color: white">{{ aboutInfo1 }}</h3>
      </div>
      <div>
        <span style="width: 80%; font-size: 20px; color: white;">{{ aboutInfo2 }}</span>
      </div>
      <div style="margin-top: 50px">
        <el-button type="primary" style="font-size: 20px;">了解更多</el-button>
      </div>
    </div>

    <div class="part2">
      <div class="partheader">
        <h>公司简介</h>
      </div>
      <div class="part2_row">
        <div class="part2_img">
          <img style="width: 360px; height: 260px" src="../../assets/about/gongsi.png">
        </div>
        <div class="part2_text">
          <h3>企业定位</h3>
          <p>作为软件与信息技术服务提供商，为客户提供软件及数字化技术服务和产品，助力千行百业迈入数字化、智能化新时代</p>
          <h3>企业愿景</h3>
          <p>成为数字技术服务领导企业，各行业客户数字化转型可信赖合作伙伴</p>
        </div>
      </div>
    </div>

    <!--荣誉资质-->
    <div class="part3_0">
      <div class="partheader">
        <h>荣誉资质</h>
      </div>
      <div class="part3_0_card-row">
        <div class="card_div">
          <el-card class="part3_0_box-card1">
            <div>
              <img style="width: 100%; height: 100%"  src="../../assets/about/zizhi1.png">
            </div>
          </el-card>
          <el-card class="part3_0_box-card2">
            <div>
              <img style="width: 100%; height: 100%"  src="../../assets/about/zizhi2.png">
            </div>
          </el-card>
        </div>
      </div>
    </div>

    <!--知识产权-->
    <div class="part3">
      <div class="partheader">
        <h>知识产权</h>
      </div>
      <div class="part3_card-row">
        <el-card class="part3_box-card">
          <div>
            <img style="width: 100%; height: 100%"  src="../../assets/about/zhishichanquan1.png">
          </div>
        </el-card>
        <el-card class="part3_box-card">
          <div>
            <img style="width: 100%; height: 100%"  src="../../assets/about/zhishichanquan2.png">
          </div>
        </el-card>
        <el-card class="part3_box-card">
          <div>
            <img style="width: 100%; height: 100%"  src="../../assets/about/zhishichanquan3.png">
          </div>
        </el-card>
        <el-card class="part3_box-card">
          <div>
            <img style="width: 100%; height: 100%"  src="../../assets/about/zhishichanquan4.png">
          </div>
        </el-card>
      </div>
      <div class="part3_card-row">
        <el-card class="part3_box-card">
          <div>
            <img style="width: 100%; height: 100%"  src="../../assets/about/zhishichanquan5.png">
          </div>
        </el-card>
        <el-card class="part3_box-card">
          <div>
            <img style="width: 100%; height: 100%"  src="../../assets/about/zhishichanquan6.png">
          </div>
        </el-card>
        <el-card class="part3_box-card">
          <div>
            <img style="width: 100%; height: 100%"  src="../../assets/about/zhishichanquan7.png">
          </div>
        </el-card>
        <el-card class="part3_box-card">
          <div>
            <img style="width: 100%; height: 100%" src="../../assets/about/zhishichanquan8.png">
          </div>
        </el-card>
      </div>
    </div>
    <div class="part4">
      <div class="partheader">
        <h>团队精英</h>
      </div>
      <div class="part4_card-row">
        <el-card class="part4_box-card">
          <img style="width: 180px; height: 180px; border-radius: 50%; margin-left: 20%; margin-top: 10%" src="../../assets/about/renwu3.png">
          <h3 style="margin-top: 20px; margin-bottom: 20px; margin-left: 30%; font-size: 25px; font-weight: bold; color: dimgray;">李某某 / CEO</h3>
          <p style="margin-top: 20px; font-size: 14px; color: dimgray; line-height: 1.5">
            毕业于武汉大学，连续创业者，5年创业经验，高级产品经理，前端工程师，擅长软件解决方案设计。
          </p>
        </el-card>
        <el-card class="part4_box-card">
          <img style="width: 180px; height: 180px; border-radius: 50%; margin-left: 20%; margin-top: 10%" src="../../assets/about/renwu3.png">
          <h3 style="margin-top: 20px; margin-bottom: 20px; margin-left: 30%; font-size: 25px; font-weight: bold; color: dimgray;">马某某 / CTO</h3>
          <p style="margin-top: 20px; font-size: 14px; color: dimgray; line-height: 1.5">
            澳大利亚伍伦贡大学计算机双硕士，高级全栈软件工程师，架构师，11年开发经验，擅长算法设计、软件逆向工程。
          </p>
        </el-card>
        <el-card class="part4_box-card">
          <img style="width: 180px; height: 180px; border-radius: 50%; margin-left: 20%; margin-top: 10%" src="../../assets/about/renwu3.png">
          <h3 style="margin-top: 20px; margin-bottom: 20px; margin-left: 20%; font-size: 25px; font-weight: bold; color: dimgray;">唐某某/ 硬件CTO</h3>
          <p style="margin-top: 20px; font-size: 14px; color: dimgray; line-height: 1.5">
            华中科技大学电子工程硕士，高级硬件工程师，7年工作经验，曾就职华为，主要从事通讯工程开发。
          </p>
        </el-card>
      </div>
    </div>
    <BottomDiv></BottomDiv>
  </div>
</template>

<script>
import BottomDiv from "@/components/BottomDiv.vue";

export default {
  name: 'HelloWorld',
  components: { BottomDiv },
  data() {
    return {
      activeMenu: "1",
      imagebox: [
        {id:0,idView:require('../../assets/imagebox/1.png')},
        {id:1,idView:require('../../assets/imagebox/2.png')},
        {id:2,idView:require('../../assets/imagebox/3.png')},
        {id:3,idView:require('../../assets/imagebox/4.png')}
        //imagebox是assets下一个放图片的文件夹
      ],

      aboutInfo1: "关于我们",
      aboutInfo2: "西安彼维软件科技有限公司是一家专注于技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广型的企业",

    };
  },

  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .pic {
    width: 100%;
    height: 450px;
    background-image: url("../../assets/imagebox/1.png");
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .part2 {
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .part2_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-items: center;
  }

  .part2_img {
    width: 45%;
    height: 500px;
    text-align: right;
    padding-top: 30px;

  }

  .part2_text {
    width: 55%;
    height: 500px;
    padding-top: 35px;
    padding-left: 30px;
    padding-right: 20%;
  }

  .part3_0 {
    width: 100%;
    height: 600px;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .part3_0_card-row {
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card_div {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
  }

  .part3_0_box-card1 {
    width: 58%;
    height: 90%;
    margin-left: 20px;
    margin-right: 20px;
  }

  .part3_0_box-card2 {
    width: 29%;
    height: 90%;
    margin-left: 20px;
    margin-right: 20px;
  }

  .part3_0_box-card1:hover {
  //box-shadow: 0 1px 6px rgba(255, 255, 255, 0.932);
    border-color: #eee;
    transition: all 0.2s ease-in-out;
    opacity: 95%;
  //background-image: linear-gradient(to bottom right, blanchedalmond, yellowgreen);
    transform: scale(1.1);
  }

  .part3_0_box-card2:hover {
  //box-shadow: 0 1px 6px rgba(255, 255, 255, 0.932);
    border-color: #eee;
    transition: all 0.2s ease-in-out;
    opacity: 95%;
  //background-image: linear-gradient(to bottom right, blanchedalmond, yellowgreen);
    transform: scale(1.1);
  }

  .part3 {
    width: 100%;
    height: 1060px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .part3_card-row {
    display: flex;
    flex-direction: row;
    justify-items: center;
  }

  .part3_box-card {
    margin-top: 50px;
    margin-left: 20px;
    margin-right: 20px;
    width: 310px;
    height: 400px;
  //background-color: ;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .part3_box-card:hover {
  //box-shadow: 0 1px 6px rgba(255, 255, 255, 0.932);
    border-color: #eee;
    transition: all 0.2s ease-in-out;
    opacity: 95%;
  //background-image: linear-gradient(to bottom right, blanchedalmond, yellowgreen);
    transform: scale(1.2);
  }



  .part4 {
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: whitesmoke;
  }

  .part4_card-row {
    display: flex;
    flex-direction: row;
    justify-items: center;

  }

  .part4_box-card {
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
    width: 350px;
    height: 430px;
  //background-color: ;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .part4_box-card:hover {
  //box-shadow: 0 1px 6px rgba(255, 255, 255, 0.932);
    border-color: #eee;
    transition: all 0.2s ease-in-out;
    opacity: 95%;
  //background-image: linear-gradient(to bottom right, blanchedalmond, yellowgreen);
    transform: scale(1.1);
  }
  p {
    line-height: 2; /* 设置行距为1.5倍字体大小 */
  }

</style>
