import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 角色列表
    roles:[],

    // 部门列表
    depts:[],
    deptTree: []

  },
  getters: {
  },
  mutations: {
    setRole(state, roles){state.roles = roles},
    setDepts(state, depts){state.depts = depts},
    setDeptTree(state, deptTree){state.deptTree = deptTree},
  },
  actions: {
  },
  modules: {
  }
})
