import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import Index from '../views/Index.vue'
import Solution from '../views/solution/Solution.vue'
import Case from '../views/cases/Case.vue'
import Business from '../views/business/Business.vue'
import About from '../views/about/About.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
    // component: Main
  },
  {
    path: '/main',
    name: 'main',
    component: Main,
    children: [
      {
        path: '/index',
        name: 'index',
        component: Index
      },
      {
        path: '/solution',
        name: 'solution',
        component: Solution,
      },
      {
        path: '/case',
        name: 'case',
        component: Case,
      },
      {
        path: '/business',
        name: 'busuness',
        component: Business,
      },
      {
        path: '/about',
        name: 'about',
        component: About,
      },
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // 路由跳转后，让页面回到顶部
  // chrome
  document.body.scrollTop = 0; // firefox
  document.documentElement.scrollTop = 0; // safari
  window.pageYOffset = 0; // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
  next();
  //to 即将进入的路由
  //from 即将离开的路由
  //next 放行
});

export default router
